import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Space } from 'antd'
import { translate } from '../../../core'
import { Combo, ComboOption, CopyToClipboard, NumericInput, Section } from '../../../core/component'
import { Item, ItemEntry, ItemType } from '../type'
import { useContext, useEffect, useState } from 'react'
import { ItemFormJewelery } from './ItemFormJewelery'
import { ItemFormWatch } from './ItemFormWatch'
import { ItemFromGiftwareTableware } from './ItemFromGiftwareTableware'
import { Contact } from '../../contact/type/contact'
import { dateFormat, makeAnObjectEmpty, stringConcat } from '../../../core/until'
import { ItemFormStone } from './ItemFormStone'
import { KeyValuePair, PageContextValue, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { ItemPageContext } from './ItemPageContext'
import { ItemFormImages } from './ItemFormImages'
import { ItemFormQuantity } from './ItemFormQuantity'
import { ItemFormSales } from './ItemFormSales'
import { ItemFormComponents } from './ItemFormComponents'
import { InputCard } from './InputCard'
import styles from './ItemForm.module.scss'
import { useItemData } from '../hook/item-data-hook'
import { useLazyQuery } from '@apollo/client'
import { ContactFindRequest } from '../../contact/type/contact.query'
import { GetVendorsForLookup } from '../../contact/graphql/contact.queries.graphql'
import { ItemFormCategory } from './ItemFormCategory'

export const ItemForm = () => {
  const {form, save, entity, onFormChange} = useContext<PageContextValue<Item, ItemEntry>>(ItemPageContext)
  const [type, setType] = useState<ItemType>()
  const {toEntry} = useItemData()
  const [findVendors] = useLazyQuery<
    QueryPageableResponse<Contact, 'contactsFind'>,
    QueryPageableRequest<ContactFindRequest>>(GetVendorsForLookup)

  const typeComponentMapper = {
    [ItemType.Jewelery]: <ItemFormJewelery/>,
    [ItemType.Watch]: <ItemFormWatch/>,
    [ItemType.Giftware]: <ItemFromGiftwareTableware type='giftware'/>,
    [ItemType.Stone]: <ItemFormStone/>,
    [ItemType.Tableware]: <ItemFromGiftwareTableware type='tableware'/>,
  }

  const typeOptions = Object.entries({
    [ItemType.Jewelery]: ItemType.Jewelery,
    [ItemType.Watch]: ItemType.Watch,
    [ItemType.Giftware]: ItemType.Giftware,
    [ItemType.Stone]: ItemType.Stone,
    [ItemType.Tableware]: ItemType.Tableware,
  }).map<ComboOption<KeyValuePair>>(([key, value]) => ({
    value: key,
    display: value,
    item: {key: key.toString(), value},
  }))

  const handleListPriceChange = () => {
    if (type !== ItemType.Jewelery)
      return

    const listPrice = form?.getFieldValue('listPrice')

    if (!listPrice)
      return

    const oursPrice = form?.getFieldValue('oursPrice')

    if (oursPrice)
      return

    form?.setFieldValue('oursPrice', Number(listPrice) / 2)
  }

  useEffect(() => {
    if (!entity) return

    const formContent = form?.getFieldsValue()
    form?.setFieldsValue(makeAnObjectEmpty(formContent))
    form?.setFieldsValue(toEntry(entity))
    setType(entity.type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  return (
    <Form
      onFieldsChange={onFormChange}
      layout='horizontal'
      form={form}
      onFinish={save}
      className={styles['form-root']}
    >
      <Section noMargin>
        <Row gutter={[16, 16]} className='mt-2'>
          <Col span={5}>
            <Form.Item
              label={translate('type')}
              name='type'
              rules={[
                {
                  required: true,
                  message: translate('missing_field_message', 'type'),
                },
              ]}
            >
              <Combo<any, ItemType>
                size='middle'
                options={typeOptions}
                onSelect={(value) => {
                  setType(value)
                }}
                onBound={({value}: { value: ItemType }) => setType(value)}
              />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item label={translate('description')} name='description' className='mb-0'>
              <Input/>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label={translate('purchase_date_abbr')} name='dateOfPurchase'>
              <DatePicker format={dateFormat} style={{width: '100%'}}/>
            </Form.Item>
          </Col>

          <Col span={4} className='d-flex align-items-end'>
            <Form.Item name='showOnline' valuePropName='checked'>
              <Checkbox>{translate('show', 'on', 'web')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
              }}
            >
              <InputCard className='py-0 mb-0'>
                <Row>
                  <Col span={24}>
                    <Space>
                      <CopyToClipboard
                        text={entity?.sku ?? ''}
                        tooltip={translate('copy', 'sku')}
                      />
                      {translate('sku')}
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name='sku'
                      rules={[
                        {
                          required: true,
                          message: translate('missing_field_message', 'sku'),
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className='input-disable-clear'
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>{translate('vendor')}</Col>
                  <Col span={24}>
                    <Form.Item name='vendorId'>
                      <Combo<Contact, number>
                        size='middle'
                        fetch={async ({request, value, search}) => {
                          const {data} = await findVendors({
                            variables: {
                              request: {
                                take: request?.take ?? 100,
                                skip: request?.skip ?? 0,
                                searchVendor: search,
                                id: value,
                              }
                            }
                          })

                          const list = data?.contactsFind.data ?? []

                          return list.map((e) => ({
                            value: e.id || 0,
                            display: stringConcat(e.contactId, ' - ', e.company),
                            item: e || {},
                          }))
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>{translate('style', '/', 'pattern')}</Col>
                  <Col span={24}>
                    <Form.Item name='style'>
                      <Input/>
                    </Form.Item>
                  </Col>
                </Row>
              </InputCard>

              <InputCard className='py-0 mb-0' title={translate('dollars')}>
                <Space direction='vertical'>
                  <table className={['table table-borderless', styles['dollars-table']].join(' ')}>
                    <tbody>
                    <tr>
                      <td></td>
                      <td className='text-center'>{translate('price')}</td>
                      <td className='text-center'>{translate('sale')}</td>
                    </tr>
                    <tr>
                      <td className='text-center'>{translate('cost')}</td>
                      <td>
                        <Form.Item noStyle name='costPrice'>
                          <Input style={{width: 70}}/>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item noStyle name='costSale'>
                          <Input style={{width: 70}}/>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'>{translate('list')}</td>
                      <td>
                        <Form.Item noStyle name='listPrice'>
                          <NumericInput
                            style={{width: 70}}
                            onBlur={handleListPriceChange}
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item noStyle name='listSale'>
                          <NumericInput
                            style={{width: 70}}
                          />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'>{translate('ours')}</td>
                      <td>
                        <Form.Item noStyle name='oursPrice'>
                          <NumericInput style={{width: 70}}/>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item noStyle name='oursSale'>
                          <NumericInput style={{width: 70}}/>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td className='text-center'>{translate('sale')}</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className='text-center'>{translate('start')}</td>
                      <td>
                        <Form.Item noStyle name='saleStartDate'>
                          <DatePicker
                            allowClear
                            suffixIcon={null}
                            format={dateFormat}
                            placeholder={''}
                            style={{width: 140}}
                          />
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className='text-center'>{translate('end')}</td>
                      <td>
                        <Form.Item noStyle name='saleEndDate'>
                          <DatePicker
                            allowClear
                            suffixIcon={null}
                            format={dateFormat}
                            placeholder={''}
                            style={{width: 140}}
                          />
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                    </tbody>
                  </table>
                </Space>
              </InputCard>
              <ItemFormQuantity/>
              <ItemFormSales/>
              <InputCard className='py-0 mb-0'>
                <Form.Item noStyle name='images'>
                  <ItemFormImages/>
                </Form.Item>
              </InputCard>
            </div>
          </Col>
        </Row>

        <InputCard className='mb-0' title={translate('category')}>
          <Form.Item name='category'>
            <ItemFormCategory/>
          </Form.Item>
        </InputCard>

        <Row gutter={[16, 16]}>
          <InputCard title={translate('notes')} noborder>
            <Form.Item name='notes'>
              <Input.TextArea rows={8}/>
            </Form.Item>
          </InputCard>

          <Col span={14}>
            <InputCard title={translate('catalog', 'description')} noborder>
              <Col span={24}>
                <Form.Item name='catalogDescription'>
                  <Input.TextArea rows={8}/>
                </Form.Item>
              </Col>
            </InputCard>
          </Col>

          <Col span={4} className='d-flex flex-column justify-content-center align-items-center '>
            <Button>{translate('copy')}</Button>
            <span className='mt-1'>{translate('to', 'item', 'number_abbr')}</span>
            <Input className='mt-1'/>
          </Col>
        </Row>
      </Section>

      {type && typeComponentMapper[type]}

      <ItemFormComponents/>
    </Form>
  )
}
