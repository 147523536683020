import { translate } from '../../../core'
import { Section } from '../../../core/component'
import { Tabs, TabsProps } from 'antd'
import { ItemCategoryList } from './ItemCategoryList'
import { InventoryQuantityHistoryList } from './InventoryQuantityHistoryList'

export const InventorySettingsContent = () => {
  const items: TabsProps['items'] = [
    {
      label: translate('item', 'category'),
      key: 'item-category',
      children: <ItemCategoryList/>
    },
    {
      label: translate('quantity', 'history'),
      key: 'quantity-history',
      children: <InventoryQuantityHistoryList/>,
    },
    {
      label: translate('stone', 'quantity', 'history'),
      key: 'stone-quantity-history',
      children: <InventoryQuantityHistoryList
        type='Stone'
      />,
    }
  ]

  return (
    <Section noMargin>
      <Tabs
        defaultActiveKey='quantity-history'
        items={items}
      />
    </Section>
  )
}
