import { gql , TypedDocumentNode} from '@apollo/client'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Appointment } from '../type/appointment'

export const GetAppointmentQuery: TypedDocumentNode<
  QueryPageableResponse<Appointment, 'appointmentsFind'>,
  QueryPageableRequest
>= gql`
    query GetAppointmentQuery($request: AppointmentFindRequestInput!) {
        appointmentsFind(request: $request) {
            data {
                id
                createdAt
                firstName
                lastName
                email
                phone
                appointmentDate
                additionalInfo
                seen
                replied
            }
            count
        }
    }
`

export const GetNewAppointmentCountQuery: TypedDocumentNode<{appointmentsNewCount: number}> = gql`
    query GetAppointmentCount{
        appointmentsNewCount
    }
`

export const SetAppointmentAsSeenMutation: TypedDocumentNode<void, {id: number}> = gql`
    mutation SetAppointmentAsSeen($id: Int!) {
        appointmentSetAsSeen(id: $id)
    }
`

export const ToggleReplyAppointmentMutation: TypedDocumentNode<void, {id: number}> = gql`
    mutation ToggleReplyAppointment($id: Int!) {
        appointmentToggleReply(id: $id)
    }
`