import React, { FC, useState } from 'react'
import { InventoryQuantityHistoryQuery } from '../graphql/inventory-quantity-history.graphql'
import { useQuery } from '@apollo/client'
import { translate } from '../../../core'
import { dateDisplay, dateFormatAndTimeSlashed } from '../../../core/until'
import { Button, Flex, Space, Table } from 'antd'
import { InventoryQuantityHistory } from '../type/inventory-quantity-history'
import { FilterOutlined, SyncOutlined } from '@ant-design/icons'
import { ItemFormSelector } from './ItemFormSelector'
import { ItemType } from '../type'

export const InventoryQuantityHistoryList: FC<{ type?: 'General' | 'Stone' }> = ({type}) => {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [itemId, setItemId] = useState<number>()
  const {data, loading, refetch} = useQuery(InventoryQuantityHistoryQuery, {
    variables: {
      request: {
        take: pageSize,
        skip: currentPage * pageSize,
        itemId,
      },
    },
    skip: !itemId,
  })

  const quantityColumnSize = type === 'Stone' ? 130 : 200

  const stoneColumnsQuantityColumns = [
    {
      dataIndex: 'quantity',
      title: translate('number_of_pieces'),
      width: quantityColumnSize,
      render: (value: InventoryQuantityHistory['quantity']) => value.numberOfPieces,
    },
    {
      dataIndex: 'quantity',
      title: translate('sale'),
      width: quantityColumnSize,
      render: (value: InventoryQuantityHistory['quantity']) => value.sale,
    },
    {
      dataIndex: 'quantity',
      title: translate('mount'),
      width: quantityColumnSize,
      render: (value: InventoryQuantityHistory['quantity']) => value.mount,
    },
    {
      dataIndex: 'quantity',
      title: translate('balance'),
      width: quantityColumnSize,
      render: (value: InventoryQuantityHistory['quantity']) => value.balance,
    },
  ]

  return (
    <>
      <Flex className='p-2 mb-3'>
        <Flex className='w-100' justify='start' align='start'>
          <Space>
            <FilterOutlined/>
            <ItemFormSelector
              style={{width: 200}}
              onChange={setItemId}
              defaultRequest={{
                types: type === 'Stone'
                  ? [ItemType.Stone]
                  : [
                    ItemType.Jewelery,
                    ItemType.Watch,
                    ItemType.Giftware,
                    ItemType.Tableware,
                  ]
              }}
            />

            <Button
              type='text'
              shape='circle'
              icon={<SyncOutlined/>}
              onClick={() => refetch()}
            />
          </Space>
        </Flex>
      </Flex>

      <Table<InventoryQuantityHistory>
        loading={loading}
        columns={[
          {
            dataIndex: 'createdAt',
            title: translate('created_at'),
            width: 200,
            render: value => dateDisplay(value, dateFormatAndTimeSlashed),
          },
          {
            dataIndex: 'quantity',
            title: translate('on_hand'),
            width: quantityColumnSize,
            render: (value: InventoryQuantityHistory['quantity']) => value.onHand,
          },
          {
            dataIndex: 'quantity',
            title: translate('display'),
            width: quantityColumnSize,
            render: (value: InventoryQuantityHistory['quantity']) => value.display,
          },
          {
            dataIndex: 'quantity',
            title: translate('back_order'),
            width: quantityColumnSize,
            render: (value: InventoryQuantityHistory['quantity']) => value.backOrder,
          },
          ...(type === 'Stone' ? stoneColumnsQuantityColumns : []),
          {
            dataIndex: 'description',
            title: translate('description'),
          }
        ]}
        dataSource={data?.inventoryQuantityHistoryFind.data.map(it => ({key: it.id.toString(), ...it})) ?? []}
        pagination={{
          total: data?.inventoryQuantityHistoryFind.count,
          position: ['bottomCenter'],
          pageSize: pageSize,
          showSizeChanger: true,
        }}
        onChange={e => {
          if (e.current)
            setCurrentPage(e.current - 1)

          if (e.pageSize)
            setPageSize(e.pageSize)
        }}
      />
    </>
  )
}